import React from 'react'
import s from './MediaContainer.module.scss'
import { Image } from '../../components/core/image/Image'
import { RichText } from 'prismic-reactjs'
import { Section } from '../../components/core/section/Section'
import { Text } from '../../components/core/text/Text'

const MediaContainer = ({ slice }) => {
    return (
        <Section container className={s.section}>
            {slice.primary.image?.url ? (
                <Image className={s.media} src={slice.primary.image.url} />
            ) : (
                <div
                    className={s.media}
                    dangerouslySetInnerHTML={{
                        __html: slice.primary.media.html,
                    }}
                />
            )}
            <Text className={s.imageText} variant="p-small">
                {RichText.asText(slice.primary.imageText)}
            </Text>
        </Section>
    )
}

export default MediaContainer
