import Prismic from '@prismicio/client'
import Link from 'next/link'

import smConfig from './sm.json'

if (!smConfig.apiEndpoint) {
    console.warn(
        "Looks like Slice Machine hasn't been bootstraped already.\nCheck the `Getting Started` section of the README file :)"
    )
}

export const apiEndpoint = smConfig.apiEndpoint

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = ''

export const repoName = 'minningar'

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
    if (doc.type === 'blog') {
        return `/advice/${doc.uid}`
    }
    if (doc.type === 'hagnytar_upplysingar') {
        return `/advice`
    }
    if (doc.type === 'frodleikur-grein') {
        return `/articles/${doc.uid}`
    }
    return '/'
}

export const customLink = (type, element, content, children, index) => (
    <Link
        key={index}
        href={linkResolver(element.data)}
        as={linkResolver(element.data)}
    >
        {content}
    </Link>
)

export const Router = {
    routes: [{ type: 'blog', path: '/:uid' }],
    href: (type) => {
        const route = Router.routes.find((r) => r.type === type)
        return route && route.href
    },
}

export const Client = (req = null, options = {}) =>
    Prismic.client(
        apiEndpoint,
        Object.assign({ routes: Router.routes }, options)
    )
