// This file is generated by Slice Machine. Update with care!

import Card from './Card'
import ImageQuote from './ImageQuote'
import MediaContainer from './MediaContainer'
import Quote from './Quote'
import ServiceCard from './ServiceCard'
import TextBlock from './TextBlock'
import TwoPictureTextBlock from './TwoPictureTextBlock'
import Video from './Video'

export {
    Card,
    ImageQuote,
    MediaContainer,
    Quote,
    ServiceCard,
    TextBlock,
    TwoPictureTextBlock,
    Video,
}

export const components = {
    card: Card,
    image_quote: ImageQuote,
    media_container: MediaContainer,
    quote: Quote,
    service_card: ServiceCard,
    text_block: TextBlock,
    two_picture_text_block: TwoPictureTextBlock,
    video: Video,
}
