// React&NextJS
import React, { useState, useEffect } from 'react'
// 3rd party libraries
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
// Services&Helper functions
import helperFunctions from '../../../utils/helperFunctions'
// Styles
import s from './GalleryWithTimer.module.scss'
// Components
import { Button } from '../../core/button/Button'
import Card from '../../cards/card/Card'
import { Container } from '../../core/container/Container'
import { usePlausible } from 'next-plausible'
import { Text } from '../../core/text/Text'

const variants = {
    enter: {
        opacity: 0,
    },

    center: {
        zIndex: 1,
        transform: 'translate(0px, 0px)',
        opacity: 1,
        transition: { duration: 1 },
    },
    exit: {
        zIndex: 0,
        opacity: 0,
        transition: { duration: 1 },
    },
}

const variants2 = {
    initial: { transform: 'translate3d(0px, 0px, 0px) scale(0, 1)' },
    center: {
        transform: 'translate3d(0px, 0px, 0px) scale(1, 1)',
    },
    exit: {},
}

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */

export const GalleryWithTimer = ({ hits }) => {
    const [[page, direction], setPage] = useState([0, 0])
    const currentCard = hits[page % hits.length]
    const [ref] = useInView()
    const plausible = usePlausible()
    const controls = useAnimation()

    useEffect(() => {
        controls.start('center').then(() => {
            controls.set('initial')
            paginate(1)
        })
    }, [page])

    // We only have 5 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
    // then wrap that within 0-2 to find our image ID in the array below. By passing an
    // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
    // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.

    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection])
    }

    const birthDate = helperFunctions.getDate(currentCard.data.birth)
    const deathDate = helperFunctions.getDate(currentCard.data.death)
    const biography =
        currentCard.data.biography && JSON.parse(currentCard.data.biography)

    return (
        <Container className={s.container}>
            <Text variant="h1" tag="h2" align="center" className={s.header}>
                Nýjar minningarsíður
            </Text>
            {/* @ts-ignore */}
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    ref={ref}
                    key={page}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    className={s.motion}
                >
                    <Card
                        description={biography}
                        header={currentCard.data.fullName}
                        headerSize="h3"
                        imageSrc={currentCard.data.profileImg}
                        lifeTime={`${birthDate} - ${deathDate}`}
                        size="1"
                        to={[
                            {
                                link: `/memories/${currentCard.objectID}`,
                                label: 'Lesa meira',
                            },
                        ]}
                        imagePosition={
                            (page % hits.length) % 2 == 0 ? 'r' : 'l'
                        }
                        className={s.card}
                    />
                </motion.div>
            </AnimatePresence>

            {/* Line with buttons */}
            <div className={s.flex}>
                <div className={s.bg}>
                    <motion.div
                        className={s.timer}
                        variants={variants2}
                        initial={'initial'}
                        animate={controls}
                        transition={{
                            duration: 10,
                            ease: 'linear',
                        }}
                    />
                </div>
                <Button
                    to="/memories"
                    variant="arrow"
                    onClick={() => plausible('homepage-skodaFleiri-button')}
                >
                    Skoða fleiri minningarsíður
                </Button>
            </div>
        </Container>
    )
}
