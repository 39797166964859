// React&NextJS
import { GetServerSideProps } from 'next'
// 3rd party libraries
import algoliasearch from 'algoliasearch'
// Styles
import styles from '../styles/Home.module.scss'
import c from 'classnames'
// Components

import { Button } from '../components/core/button/Button'
import { Container } from '../components/core/container/Container'
import { GalleryWithTimer } from '../components/components/galleryWithTimer/GalleryWithTimer'
import { Image } from '../components/core/image/Image'
// import MailList from '../components/components/mailList/MailList'
import Meta from '../components/meta/Meta'
import { RecentlyDeceacedModule } from '../components/components/recentlyDeceacedModule/RecentlyDeceacedModule'
// import { Section } from '../components/core/section/Section'
import { Text } from '../components/core/text/Text'
import Card from '../components/cards/card/Card'
// import { VimeoPlayer } from '../components/core/vimeoPlayer/VimeoPlayer'
import { ServiceCard } from '../slices'
import { usePlausible } from 'next-plausible'
import MailList from '../components/components/mailList/MailList'
import { VimeoPlayer } from '../components/core/vimeoPlayer/VimeoPlayer'
import { Section } from '../components/core/section/Section'
import { UpcomingFuneralsModule } from '../components/components/upcomingFuneralsModule/UpcomingFuneralsModule'

const client = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
)
const index = client.initIndex('memories')

export const getServerSideProps: GetServerSideProps = async ({ res }) => {
    res.setHeader(
        'Cache-Control',
        'public, s-maxage=600, stale-while-revalidate=300'
    )
    let memHits = {}

    // Get all the memories hits from firebase with algolia
    try {
        memHits = await index.search('', {
            filters: 'NOT data.scraped=1',
        })
    } catch (e) {
        console.error((e as Error).message)
    }

    return {
        props: {
            memHits,
        },
    }
}

export default function Home({ memHits }) {
    const plausible = usePlausible()
    return (
        <>
            <Meta
                title="Minningar.is"
                seoTitle="Minningar"
                url="https://minningar.is/"
                type="website"
                description="Minningar.is er gjaldfrjáls vefur sem auðveldar fólki að varðveita minningu látins ástvinar í öruggu og aðgengilegu umhverfi. Verið velkomin."
                image="https://ik.imagekit.io/minningar/Minningar_7V4A5213_01-min_5-f7B_aBV.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1643189239548&tr=w-1200%2Ch-628%2Cfo-auto"
            />
            <div className={styles.home}>
                <video
                    className={styles.background}
                    loop
                    autoPlay
                    muted
                    playsInline
                >
                    <source src="/videos/forsidurHero.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <img
                    className={styles.background_fallback}
                    src="/images/background.jpg"
                    alt=""
                />

                <section className={styles.headerSection}>
                    <Container className={styles.header}>
                        <Text
                            className={styles.header__item}
                            variant="h1"
                            align="center"
                        >
                            Minningin lifir
                        </Text>
                        <Text
                            variant="p-18"
                            className={styles.header__item}
                            align="center"
                        >
                            {/* Þessi vefur er hugsaður til þess að auðvelda fólki að
                        halda utan um minningar sínar um látinn ástvin á öruggan
                        og aðgengilegan hátt. Hér geturðu meðal annars stofnað
                        minningarsíðu, sett inn greinar, myndir, kveðjur og
                    annað efni. */}
                            Minningar.is er gjaldfrjáls vefur sem auðveldar
                            fólki að varðveita minningu látins ástvinar í öruggu
                            og aðgengilegu umhverfi. Verið velkomin.
                        </Text>
                        {/* <button
                            type="button"
                            onClick={() => {
                                throw new Error('Sentry Frontend Error')
                            }}
                        >
                            Throw error
                        </button> */}
                        <div className={c(styles.header__buttons)}>
                            <Button
                                variant="ghost"
                                to="/memories/form?recent=true"
                                onClick={() =>
                                    plausible('homepage-tilkynnaAndlat-button')
                                }
                            >
                                Tilkynna andlát
                            </Button>

                            <Button
                                variant="arrow"
                                to="/memories/form"
                                onClick={() =>
                                    plausible(
                                        'homepage-stofnaMinningarTop-button'
                                    )
                                }
                            >
                                Stofna minningarsíðu
                            </Button>
                        </div>
                    </Container>
                </section>

                {/* Recently deceaced */}
                <Section>
                    <RecentlyDeceacedModule />
                </Section>

                {/* tilkynna andlát */}
                <ServiceCard
                    slice={{
                        primary: {
                            title: 'Tilkynna andlát',
                            description:
                                'Það er einfalt og gjaldfrjálst að tilkynna andlát á Minningar.is en við það birtist andlátstilkynning á vefnum undir „Nýleg andlát.“ Hægt er að deila andlátstilkynningunni sem fallegu spjaldi á samfélagsmiðlum sem vísar beint inn á minningarsíðu viðkomandi.',
                            buttonLink: '/memories/form',
                            buttonText: 'Tilkynna andlát',
                        },
                    }}
                />

                <GalleryWithTimer hits={memHits.hits} />

                {/* Upcoming funerals */}
                <Section>
                    <UpcomingFuneralsModule />
                </Section>

                {/* Að hverju þarf að huga? */}
                <Section>
                    <Container>
                        <Image
                            src="https://ik.imagekit.io/minningar/static/tr:w-700/gemma-evans-KY6M76-LnF0-unsplash.jpeg"
                            alt="Hjón á gamalli myndafilmu"
                            lazy
                            className={styles.hverjuImage}
                        />
                        <div className={styles.hverjuText}>
                            <Text variant="h1" tag="h2">
                                Að hverju þarf að huga?
                            </Text>
                            <Text variant="p-18">
                                Í kjölfar andláts ástvinar þarf fólk að stíga
                                mörg þungbær skref og taka fjölda erfiðra
                                ákvarðana. Til að styðja við aðstandendur geymir
                                Minningar.is ýmsar hagnýtar upplýsingar,
                                fróðleik og yfirlit yfir þjónustuaðila.
                            </Text>
                            <Button
                                variant="text"
                                to="/advice"
                                onClick={() =>
                                    plausible('homepage-LesaMeiraAdvice-button')
                                }
                            >
                                Lesa meira
                            </Button>
                        </div>
                    </Container>
                </Section>

                {/* Articles */}
                <Section>
                    <div className={styles.grid}>
                        <Card
                            size="3"
                            imageSrc="https://firebasestorage.googleapis.com/v0/b/minning-f44b2.appspot.com/o/DefaultImage.jfif?alt=media&token=214dc2f6-ae7e-439f-9bba-7381c78f39f5"
                            header="Spurt og svarað"
                            loading="lazy"
                            to={{
                                link: '/questions-and-answers',
                                label: '',
                            }}
                        ></Card>
                        <Card
                            size="3"
                            imageSrc="https://firebasestorage.googleapis.com/v0/b/minning-f44b2.appspot.com/o/podcast%2FSolveig_thumb.png?alt=media&token=d74efe2b-5be4-4f15-9446-cbf2e511bd2c"
                            header="Sirrý tekur viðtal við Sólveig Arnarsdóttur"
                            loading="lazy"
                            to={{
                                link: '/podcast?vidId=720801343',
                                label: '',
                            }}
                        ></Card>
                        <Card
                            size="3"
                            imageSrc="https://firebasestorage.googleapis.com/v0/b/minning-f44b2.appspot.com/o/services%2Fservice-card-image.jpg?alt=media&token=9c324963-442d-4d73-a707-29f0f1b36616"
                            header="Útfararþjónustur komnar á þjónustusíðuna"
                            loading="lazy"
                            to={{
                                link: '/services',
                                label: '',
                            }}
                        ></Card>
                        {/* <Card
                            size="3"
                            imageSrc="https://ik.imagekit.io/minningar/static/tr:w-800/Minningar_7V4A5680_01-min.jpg"
                            header="Um Minningar.is"
                            loading="lazy"
                            to={{ link: '/about-us', label: '' }}
                        ></Card> */}
                    </div>
                </Section>

                {/* Auglýsing */}
                <Section container>
                    <VimeoPlayer
                        vimeoId={657359998}
                        imgSrc={
                            'https://ik.imagekit.io/minningar/static/tr:w-1600/vimeoPlaceholder.png.jpg'
                        }
                    />
                </Section>

                {/* Viltu skrifa minningargrein? */}
                <ServiceCard
                    slice={{
                        primary: {
                            title: 'Viltu skrifa minningargrein?',
                            description:
                                'Það er einfalt og gjaldfrjálst að skrifa minningargrein á Minningar.is sem birtist á minningarsíðu viðkomandi.',
                            buttonLink: '/memories',
                            buttonText: 'Skrifa minningargrein',
                        },
                    }}
                />

                {/* Gjöf til þjóðar */}
                <Section>
                    <Container>
                        <Image
                            src="https://ik.imagekit.io/minningar/static/tr:w-1200/DSC02128-min.JPG"
                            alt="Hjón á gamalli myndafilmu"
                            lazy
                            className={c(styles.hverjuImage, styles.gjof)}
                        />
                        <div className={c(styles.hverjuText, styles.gjofText)}>
                            <Text variant="h1" tag="h2">
                                Gjöf til þjóðar
                            </Text>
                            <Text variant="p-18">
                                Við heimsóttum forseta Íslands, hr. Guðna Th.
                                Jóhannesson á Bessastaði sem opnaði vefinn
                                formlega með eftirfarandi orðum: „Góðar
                                Minningar lifa. Landsmönnum mun þykja vænt um að
                                geta minnst ástvina á þessum fallega vef.“
                            </Text>
                            <Button
                                variant="text"
                                to="/about-us"
                                onClick={() =>
                                    plausible('homepage-LesaMeiraGjof-button')
                                }
                            >
                                Lesa meira
                            </Button>
                        </div>
                    </Container>
                </Section>

                {/* Mail list */}
                <MailList />
            </div>
        </>
    )
}
