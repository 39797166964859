import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Section } from '../../components/core/section/Section'
import s from './TextBlock.module.scss'
import { Text } from '../../components/core/text/Text'
import { linkResolver } from '../../prismic-configuration'
import { ButtonMap } from '../../components/components/buttonMap/ButtonMap'
import { Button } from '../../components/core/button/Button'
import classnames from 'classnames'

const c = classnames.bind(s)

const TextBlock = ({ slice }) => {
    let to = {
        link: slice.primary.ButtonLink,
        label: slice.primary.ButtonText,
    }
    if (slice.primary.ButtonLink2) {
        to = [
            {
                link: slice.primary.ButtonLink,
                label: slice.primary.ButtonText,
            },
            {
                link: slice.primary.ButtonLink2,
                label: slice.primary.ButtonText2,
            },
        ]
    }

    let buttons
    if (Array.isArray(to)) {
        buttons = (
            <ButtonMap className={s.buttons}>
                {to.map((obj, i) => (
                    <Button
                        variant="text"
                        key={i}
                        label={obj.label}
                        to={obj.link}
                    />
                ))}
            </ButtonMap>
        )
    } else if (to.link) {
        buttons = (
            <Button
                className={s.buttons}
                variant="text"
                label={to.label}
                to={to.link}
            />
        )
    }
    return (
        <Section container className={c(s[slice.primary.noSpace && 'noPadd'])}>
            <Text className={s.title} variant="h3">
                {RichText.asText(slice.primary.Title)}
            </Text>

            <div className={s.textBlock}>
                <RichText
                    render={slice.primary.description}
                    linkResolver={linkResolver}
                />
            </div>
            {buttons}
        </Section>
    )
}

export default TextBlock
