// React&NextJS
import React from 'react'
// Styles
import s from './MailList.module.scss'
// Components
import { Container } from '../../core/container/Container'
import { Image } from '../../core/image/Image'
import { MailCard } from './MailCard'
import { Section } from '../../core/section/Section'

const MailList = () => {
    return (
        <Section>
            <Container className={s.mailLayout}>
                <Image
                    className={s.image}
                    width={641}
                    lazy
                    alt="Kona að skoða myndir"
                    src={
                        'https://firebasestorage.googleapis.com/v0/b/minning-f44b2.appspot.com/o/Minningar_7V4A5114_01_crop.jpg?alt=media&token=71ec6be5-734f-4691-82dd-d0284571ba4b'
                    }
                />
                <MailCard />
            </Container>
        </Section>
    )
}
export default MailList
