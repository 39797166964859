import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Text } from '../../components/core/text/Text'
import s from './ImageQuote.module.scss'
import { Section } from '../../components/core/section/Section'
import { Image } from '../../components/core/image/Image'
import { Container } from '../../components/core/container/Container'

const ImageQuote = ({ slice }) => {
    const landscape =
        slice.primary.image.dimensions.width >
        slice.primary.image.dimensions.height

    return (
        <Section>
            <Container>
                <div
                    className={
                        landscape ? s.imageContainer : s.imageContainerPortrait
                    }
                >
                    <Image className={s.image} src={slice.primary.image.url} />
                </div>
                {slice.primary.title ? (
                    <Text variant="h3" className={s.text}>
                        {RichText.asText(slice.primary.title)}
                    </Text>
                ) : (
                    <Text variant="h3" className={s.text}>
                        „Add a quote in prismic“
                    </Text>
                )}
                {slice.primary.paragraph && (
                    <Text variant="p" className={s.paragraph}>
                        {RichText.asText(slice.primary.paragraph)}
                    </Text>
                )}
            </Container>
        </Section>
    )
}

export default ImageQuote
