// React&NextJS
import React, { useState, useEffect } from 'react'
// 3rd party libraries
import algoliasearch from 'algoliasearch'
import {
    Configure,
    InstantSearch,
    connectHits,
    connectPagination,
} from 'react-instantsearch-dom'
// Services&Helper functions
import helperFunctions from '../../../utils/helperFunctions'
// Types
import { MemoryRefType, ScrapedFunerals } from '../../../types/types'
// Styles
import s from './UpcomingFuneralsModule.module.scss'
// Components
import { Button } from '../../core/button/Button'
import Card from '../../cards/card/Card'
import { Container } from '../../core/container/Container'
import { Grid } from '../../core/grid/Grid'
import { Text } from '../../core/text/Text'
import { AnimatePresence } from 'framer-motion'
import NewShareModal from '../modals/NewShareModal'

const client = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
)

type HitType = MemoryRefType & {
    objectID: string
}

type HitsType = { hits: Array<HitType> }

// hide funerals that are not supposed to be shown
const hideFuneralIds = ['jon-holm']

function Hits({ hits }: HitsType) {
    let memories = hits.map((h: any) => {
        return { ...h.data, objectID: h.objectID }
    })

    // filter out funerals that are supposed to be hidden, ids that include the hideFuneralIds array
    memories = memories.filter((m) => {
        const hide = hideFuneralIds.some((id) => m.objectID.includes(id))
        return !hide
    })

    const [isOpen, setIsOpen] = useState(false)
    const [funeral, setFuneral] = useState<ScrapedFunerals>(null)

    const closeModal = () => {
        setFuneral(null)
        setIsOpen(false)
    }

    const getStreamExternalLink = (streamLink) => {
        const externalLink = streamLink?.includes('https' || 'http')
        let ret
        if (externalLink) {
            ret = streamLink
        } else {
            ret = `https://${streamLink}`
        }
        return ret
    }

    return (
        // TODO: fix this
        // @ts-ignore
        <AnimatePresence>
            <NewShareModal
                isOpen={isOpen}
                closeModal={() => closeModal()}
                title={
                    'Viltu stofna eða benda aðstandanda á að stofna minningarsíðu?'
                }
                info={`Við sjáum að ekki er til minningarsíða um ${funeral?.fullName} á Minningar.is. Þú getur stofnað minningarsíðu í nokkrum einföldum skrefum, eða bent aðstandanda á það.`}
                data={funeral}
                imgURL="https://firebasestorage.googleapis.com/v0/b/minning-f44b2.appspot.com/o/Fallback%2FiStock-526494097-min.jpeg?alt=media&token=e574b1a7-7266-4463-a2a7-f9d54609901c"
            >
                <Button
                    type="button"
                    variant="ghost"
                    className={s.btnPadd}
                    to={`/memories/form?funeralId=${funeral?.objectID}`}
                >
                    Stofna minningarsíðu
                </Button>
                <Text variant="date">Senda hugmyndina á aðstandanda</Text>
            </NewShareModal>
            <div
                // initial={{ opacity: 0 }}
                // animate={{ opacity: 1 }}
                key={hits && hits[0]?.data?.fullName}
            >
                <Grid className={s.grid}>
                    {memories.map((m, i) => {
                        const date: string = helperFunctions.getDate(
                            m.announcements.funeral.funeralDate,
                            true
                        )

                        const streamLink = getStreamExternalLink(
                            m.announcements.funeral.streamLink
                        )

                        if (!m.scraped) {
                            const to = [
                                {
                                    link: `/memories/${m.objectID}`,
                                    label: 'Lesa meira',
                                },
                            ]

                            if (m.announcements.funeral.streamLink) {
                                to.push({
                                    link: streamLink,
                                    label: 'Hlekkur á streymi',
                                })
                            }

                            return (
                                <Card
                                    key={i}
                                    size="4"
                                    header={m.fullName}
                                    headerSize="h4"
                                    lifeTime={date}
                                    description={m.announcements.funeral.text}
                                    imageSrc={m.profileImg}
                                    to={to}
                                />
                            )
                        } else {
                            const to = [
                                {
                                    id: `${m.fullName}-funeral`,
                                    onClick: () => {
                                        setFuneral(m)
                                        setIsOpen(true)
                                    },
                                    link: '',
                                    label: 'Stofna minningarsíðu',
                                },
                            ]

                            if (m.announcements.funeral.streamLink) {
                                to.push({
                                    id: '',
                                    onClick: () => {},
                                    link: streamLink,
                                    label: 'Hlekkur á streymi',
                                })
                            }

                            return (
                                <div key={i}>
                                    <Card
                                        size="4"
                                        header={m.fullName}
                                        headerSize="h4"
                                        lifeTime={date}
                                        description={
                                            m.announcements.funeral.text
                                        }
                                        imageSrc={m.profileImg}
                                        // scrapedInfo={m.scrapeInfo}
                                        type="funeral"
                                        to={to}
                                    />
                                </div>
                            )
                        }
                    })}
                </Grid>
            </div>
        </AnimatePresence>
    )
}

export const UpcomingFuneralsModule = () => {
    const [device, setDevice] = useState(4)
    const [firstRender, setFirstRender] = useState(true)
    const CustomHits = connectHits(Hits)

    const Pagination = ({ currentRefinement, nbPages, refine }) => {
        return (
            <Container className={s.header}>
                <Text variant="h5">Útfarir á næstunni</Text>
                {device !== 1 && (
                    <div className={s.buttons}>
                        <Button
                            variant="arrow"
                            arrowDirection="l"
                            disabled={currentRefinement === 1}
                            onClick={(event) => {
                                event.preventDefault()
                                refine(currentRefinement - 1)
                            }}
                        />
                        <Button
                            variant="arrow"
                            disabled={currentRefinement === nbPages}
                            onClick={(event: React.SyntheticEvent) => {
                                event.preventDefault()
                                refine(currentRefinement + 1)
                            }}
                        />
                    </div>
                )}
            </Container>
        )
    }
    const CustomPagination = connectPagination(Pagination)

    useEffect(() => {
        function changeDevice() {
            if (window.innerWidth <= 479) {
                setDevice(1)
            } else if (window.innerWidth <= 719) {
                setDevice(2)
            } else {
                setDevice(4)
            }
        }
        window.addEventListener('resize', changeDevice)
        if (firstRender) {
            changeDevice()
            setFirstRender(false)
        }
        return () => window.removeEventListener('resize', changeDevice)
    }, [])
    var today = new Date()
    var dayFilter = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1,
        23,
        59,
        59,
        59
    ).getTime()

    return (
        <InstantSearch searchClient={client} indexName="memories_funeral">
            <CustomPagination />

            <Configure
                hitsPerPage={device}
                filters={`data.announcements.funeral.funeralDate > ${dayFilter} AND data.announcements.funeral.hold:NOW`}
            />
            <CustomHits />
            <Container>
                <Button
                    variant="arrow"
                    className={s.viewMore}
                    to="/recent-funerals"
                >
                    Skoða allar útfarir
                </Button>
            </Container>
        </InstantSearch>
    )
}
