import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Section } from '../../components/core/section/Section'
import { Container } from '../../components/core/container/Container'
import s from './Quote.module.scss'
import { Text } from '../../components/core/text/Text'
import classnames from 'classnames'

const c = classnames.bind(s)

const Quote = ({ slice }) => {
    return (
        <Section className={c(s[slice.primary.noSpace && 'smallPadd'])}>
            <Container>
                <Text variant="h3" className={s.text}>
                    {RichText.asText(slice.primary.title)}
                </Text>
            </Container>
        </Section>
    )
}

export default Quote
