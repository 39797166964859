import React from 'react'
import { RichText } from 'prismic-reactjs'
import Card from '../../components/cards/card/Card'

const Carder = ({ slice }) => {
    const to = [
        {
            link: slice.primary.buttonLink,
            label: slice.primary.buttonText,
        },
    ]
    if (slice.primary.buttonLink2) {
        to.push({
            link: slice.primary.buttonLink2,
            label: slice.primary.buttonText2,
        })
    }

    return (
        <Card
            header={RichText.asText(slice.primary.title)}
            description={RichText.asText(slice.primary.description)}
            imageSrc={slice.primary.image.url + '&h=500'}
            imagePosition={slice.primary.imagePosition}
            size="1"
            to={to}
        />
    )
}

export default Carder
