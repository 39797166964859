import React, { useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { Section } from '../../components/core/section/Section'
import { VimeoPlayer } from '../../components/core/vimeoPlayer/VimeoPlayer'
import { Text } from '../../components/core/text/Text'
import { Container } from '../../components/core/container/Container'
import s from './Video.module.scss'
import { Button } from '../../components/core/button/Button'
import ShareModal from '../../components/components/share/Share'
import { linkResolver } from '../../prismic-configuration'
import { RecentlyDeceacedModule } from '../../components/components/recentlyDeceacedModule/RecentlyDeceacedModule'
import { useRouter } from 'next/router'

const Video = ({ slice }) => {
    const [openModal, setOpenModal] = useState(false)
    const router = useRouter()
    return (
        <>
            <Section>
                <Container>
                    <VimeoPlayer
                        vimeoId={slice.primary.vimeoId}
                        imgSrc={slice.primary.imgSrc}
                    />
                    <Text className={s.paragraph}>
                        <RichText
                            render={slice.primary.description}
                            linkResolver={linkResolver}
                        />
                    </Text>
                    <Button
                        className={s.button}
                        variant="arrow"
                        icon="share"
                        onClick={() => setOpenModal(true)}
                    >
                        Deila
                    </Button>
                </Container>
                <ShareModal
                    setOpenModal={setOpenModal}
                    url={'minningar.is/' + router.asPath}
                    isOpen={openModal}
                />
            </Section>
            <RecentlyDeceacedModule />
        </>
    )
}

export default Video
