// React&NextJS
import React from 'react'
// Types
import { TooltipType } from '../../../types/types'
// Styles
import s from './Tooltip.module.scss'
import classnames from 'classnames'
// Components
import { Icon } from '../icon/Icon'
import { Text } from '../text/Text'

const c = classnames.bind(s)

export const Tooltip = ({
    disabled,
    text,
    className,
    clicked,
    setClicked,
    children,
}: TooltipType) => {
    return (
        <div className={c(s.infoContainer, s[clicked && 'active'], className)}>
            <button
                type="button"
                disabled={disabled}
                className={c(s.iconButton)}
                aria-disabled={disabled}
                onClick={() => {
                    if (setClicked) {
                        setClicked((prev) => !prev)
                    }
                }}
            >
                <Icon className={s.icon} variant="info" />
            </button>
            <div className={s.infoBox}>
                <Text variant="p-small" className={s.infoText}>
                    {text || children}
                </Text>
            </div>
        </div>
    )
}

Tooltip.defaultProps = {
    isActive: false,
}
