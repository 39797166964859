import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Image } from '../../components/core/image/Image'
import { Section } from '../../components/core/section/Section'
import s from './TwoPictureTextBlock.module.scss'
import { Text } from '../../components/core/text/Text'

const TwoPictureTextBlock = ({ slice }) => (
    <Section container>
        <div className={s.text}>
            <Text className={s.title} variant="h3">
                {RichText.asText(slice.primary.title)}
            </Text>

            <Text className={s.textBlock}>
                <RichText render={slice.primary.description} />
            </Text>
        </div>

        <div className={s.rightImageContainer}>
            <Image
                className={s.rightImage}
                src={slice.primary.rightImage.url}
                alt={slice.primary.rightImage.alt}
            />
        </div>

        <div className={s.bottomImageContainer}>
            <Image
                className={s.bottomImage}
                src={slice.primary.bottomImage.url}
                alt={slice.primary.bottomImage.alt}
            />
        </div>
    </Section>
)

export default TwoPictureTextBlock
