// React&NextJS
import React, { useState } from 'react'
// Styles
import s from './MailList.module.scss'
// Components
import { WhiteBox } from '../../core/whiteBox/WhiteBox'
import { Text } from '../../core/text/Text'
import { Input } from '../../core/input/Input'
import { Button } from '../../core/button/Button'
import { useForm } from 'react-hook-form'
import { usePlausible } from 'next-plausible'

type MailCardType = {
    email: string
}

export const MailCard = () => {
    const [state, setState] = useState(1)
    const plausible = usePlausible()

    const {
        trigger,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<MailCardType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: {
            email: '',
        },
    })

    //Function for handling submit event
    const SubmitHandler = async (data: MailCardType) => {
        setState(2)
        const res = await fetch('/api/email/mailList', {
            method: 'POST',
            body: JSON.stringify({
                email: data.email,
            }),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(async (res) => await res.json())
        if (res?.success) {
            setState(3)
        } else {
            setState(-1)
        }
        reset()
    }

    return (
        <WhiteBox className={s.formContainer}>
            <Text variant="h2">Skráðu þig á póstlista Minningar.is</Text>
            <Text variant="p">
                Við sendum þér fréttabréf með upplýsingum um nýjungar á vefnum
                og ábendingum um áhugavert efni.
            </Text>
            {state === 1 && (
                <form className={s.form} onSubmit={handleSubmit(SubmitHandler)}>
                    <Input
                        type="text"
                        name="email"
                        placeholder="Sláðu inn netfangið þitt ..."
                        className={s.mail}
                        errorMsg={errors?.email?.message}
                        control={control}
                        trigger={trigger}
                        rules={{
                            // required: {
                            //     value: true,
                            //     message: 'Netfang má ekki vera tómt.',
                            // },
                            pattern: {
                                value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, //eslint-disable-line
                                message: 'Sláðu inn gilt netfang.',
                            },
                        }}
                    ></Input>
                    <Button
                        type="submit"
                        className={s.button}
                        onClick={() =>
                            plausible('homepage-skraMigPostlisti-button')
                        }
                    >
                        Skrá mig
                    </Button>
                </form>
            )}
            {state === 2 && (
                <div className={s.loader}>
                    <div className={s.loaderInside}></div>
                </div>
            )}
            {state === 3 && (
                <div className={s.completeState}>
                    <Text variant="p" align="center">
                        Netfangi hefur verið bætt á póstlista!
                    </Text>
                    <Button
                        className={s.refreshBtn}
                        onClick={() => setState(1)}
                    >
                        Skrá annað netfang
                    </Button>
                </div>
            )}
            {state === -1 && (
                <div className={s.errorState}>
                    <Text variant="p" align="center" className={s.error}>
                        Ekki náðist að bæta netfangi á póstlista!
                    </Text>
                    <Button
                        className={s.refreshBtn}
                        onClick={() => setState(1)}
                    >
                        Reyna aftur
                    </Button>
                </div>
            )}
        </WhiteBox>
    )
}
